html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #eee;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular",
    sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.38;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #2c353d;
}

textarea,
input,
select,
button,
.button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
  resize: none;
  transition: .2s;
}

input[type='radio'] {
  box-shadow: none !important;
}

input[type='file'] {
  display: none;
}

button,
.button {
  color: rgb(93, 151, 256);
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}

button.primary,
.button.primary {
  background-color: #dddace;
  color: #2c353d;
}

button.secondary,
.button.secondary {
  background-color: #b3aea8;
  color: #fff;
}

button.decline,
.button.decline {
  background-color: #6c757d;
  color: #fff;
}

textarea:hover:not(.disabled),
input:hover:not(:disabled):not([type='file']),
button:hover:not(:disabled),
.button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active:not(:disabled),
.button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

button:disabled {
  color: #818181;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

#home {
  width: 100%;
  height: 100%;
}

#legal {
  width: 800px;
  min-height: 100vh;
  margin: 150px auto;
}

hr {
  border: none;
  height: 1px;
  background-color: #ddd;
}

.card {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
  border-radius: 5px;
  padding: 15px;
  width: 300px;
}

.card.inset {
  box-shadow: 1px 0px 5px rgba(0, 0, 0, .2) inset;
  border-radius: 10px;
}

.card.spaced {
  margin-bottom: 10px;
}

.card > h2 {
  margin-top: 0px;
}

.card select {
  margin-top: 10px;
}

.card .auth-selection-text {
  margin-top: 10px;
  font-size: 13px;
}

.card .auth-selection-text > div {
  margin-top: 10px;
}

select.inline-dropdown {
  font-size: inherit;
  padding: 2px;
  margin: 0px 5px;
}

.container {
  position: relative;
  padding: 2rem 4rem;
  margin-top: 50px;
}

h1 {
  font-size: 24px;
  color: #333;
}

.link {
  cursor: pointer;
  color: rgb(93, 151, 256);
  transition: .2s;
  text-decoration: none;
}

.link:visited {
  color: rgb(93, 151, 256);
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  vertical-align: -1px;
}

.text-centered {
  text-align: center;
}

.loading-icon span {
  margin: 0px auto;
}

.centered {
  margin: 0px auto;
  vertical-align: middle;
}

.logo {
  font-size: 20px;
  width: 300px;
  text-align: center;
  color: #2c353d;
}

.logo.inline {
  display: inline-block;
  width: auto;
}

.logo.light {
  color: #fcfcff;
}

.logo-w img {
  width: 37px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 1px;
}

.clearfix {
  zoom: 1;
  height: 50px;
}

.text-divider {
  margin: 10px 8px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: #96999e;
}

.text-divider > span {
  position: relative;
}

.text-divider > span:before,
.text-divider > span:after {
  background: #d7dade;
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
}

.text-divider > span:before {
  margin-right: 15px;
  right: 100%;
}

.text-divider > span:after {
  margin-left: 15px;
  left: 100%;
}

.status {
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.status.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.status.error {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.notFound {
  margin: 100px auto;
}

.header {
  position: fixed;
  height: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
  background-color: rgba(248, 248, 255, .8);
  z-index: 300;
  top: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  transition: .3s;
  min-width: 700px;
}

.header .logo {
  margin-top: 6px;
}

.header .logoLink {
  text-decoration: none;
  display: inline-block;
  margin-left: 50px;
  transition: .2s;
}

.header .logoLink:hover {
  opacity: .6;
}

.header .links {
  position: absolute;
  right: 15px;
  top: 5px;
}

.header .links a {
  margin-right: 50px;
}

.header .links .demo {
  margin-right: 0;
}

.header .links .demo .button {
  border-radius: 8px 0 0 8px;
}

.header .links .demo ~ a .button {
  border-radius: 0 8px 8px 0;
  color: #2c353d;
  background-color: #eee;
}

.header .links .link,
.header .links .link:visited {
  color: #2c353d;
}

.hero {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.frame-hero {
  position: relative;
  font-size: .9rem;
}

.bigText {
  font-size: 5vw;
  text-transform: uppercase;
  font-weight: lighter;
  text-align: right;
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, 0px);
}

.bigText div {
  margin-right: 2em;
}

.bigText div.noise {
  margin-right: 1.25em;
}

.biggerText {
  font-size: 1.5em;
  margin-right: .3em;
  font-weight: normal;
}

.description {
  text-align: start;
  font-size: 1.2vw;
}

.topBlurb {
  position: absolute;
  width: 500px;
  top: 30%;
  left: -1.2vw;
  line-height: 2.1vw;
  --tw-translate-y: -33.333333%;
  transform: translate(0, var(--tw-translate-y)) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.invisible {
  visibility: hidden;
}

.topBlurb .logo {
  font-size: 1.2vw;
}

.topBlurb .logo .logo-w img {
  width: 2vw;
}

.sellPoints {
  margin-top: 4vw;
}

.smallText {
  font-size: 1vw;
  text-align: left;
  font-weight: normal;
  text-transform: none;
}

.smallText b {
  margin-right: .3vw;
  text-transform: uppercase;
}

.features {
  padding: 50px;
}

.features .steps {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #dddace;
  margin: 0px -50px;
  padding: 0px 50px;
}

.features .step {
  padding: 20px 20px 40px;
}

.highlightsTitle {
  color: #444;
  margin-top: 100px;
  text-align: center;
}

.highlightsTable {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
  margin: 0px auto;
}

.features .highlights .cell {
  width: 50%;
  margin-bottom: 20px;
  color: #666;
  box-sizing: border-box;
  font-size: 1.2em;
  padding: .2em;
}

.features .highlights .cell svg {
  margin-right: 5px;
  vertical-align: -3px;
  color: #2c353d;
  font-weight: bold;
}

.footer {
  background-color: #202123;
  color: #848d97;
  padding: 30px 50px;
  min-height: 400px;
  position: relative;
}

.footer .logo {
  font-size: 1.8vw;
  vertical-align: top;
}

.footer .logo img {
  width: 3vw;
}

.footer .contact {
  color: #fcfcff;
  display: inline-block;
  margin-left: 10vw;
  font-size: 1.2vw;
  vertical-align: top;
}

.footer .contact .email {
  font-size: 2vw;
}

.footer .center-divider {
  position: absolute;
  top: 0px;
  left: calc(50% - 1px);
  width: 1px;
  height: 300px;
  background-color: #30363d;
}

.footer .libraries {
  display: inline-block;
  margin-left: 30vw;
  color: #fcfcff;
  font-size: 1.2vw;
}

.footer .copywrite {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.footer .copywrite .links a {
  margin: 0px 5px;
}

@media (max-width: 650px) {
  .header {
    min-width: 350px;
  }

  .header .logoLink {
    margin-left: 15px;
  }

  .header .logoLink:hover {
    opacity: 1;
  }

  .header .links .desktop {
    display: none;
  }

  .header .links .demo .button {
    border-radius: 8px;
  }

  .header .links .demo ~ a {
    display: none;
  }

  .bigText {
    font-size: 3.25em;
  }

  .bigText div {
    margin-right: .65em;
  }
  
  .bigText div.noise {
    margin-right: 0;
  }

  .smallText {
    font-size: .27em;
    padding: 0 3em;
  }

  .topBlurb {
    position: static;
    font-size: 1em;
    transform: none;
    margin-top: 2em;
    line-height: 1.5em;
    margin-left: 2em;
    width: calc(100% - 2em);
  }

  .topBlurb .logo {
    font-size: 1.2em;
  }
  
  .topBlurb .logo .logo-w img {
    width: 2em;
  }

  .features .steps {
    flex-direction: column;
    justify-content: center;
  }

  .features .step {
    padding: 20px;
  }

  .features .step:last-child {
    padding: 20px 20px 40px;
  }

  .highlightsTable {
    width: 80vw;
  }

  .features .highlights .cell {
    padding: .75em;
  }

  .footer {
    padding: 30px 30px;
  }

  .footer .logo {
    font-size: 1.2em;
    display: block;
    margin: 0px auto;
  }
  
  .footer .logo img {
    width: 2em;
  }
  
  .footer .contact {
    color: #fcfcff;
    display: block;
    font-size: 1em;
    margin: 1.5em auto;
    text-align: center;
  }
  
  .footer .contact .email {
    font-size: 1.2em;
  }

  .footer .center-divider {
    display: none;
  }

  .footer .libraries {
    font-size: 1.2em;
    margin: 0px auto;
    text-align: center;
    display: block;
  }

  .footer .copywrite {
    position: static;
    margin-top: 1.5em;
  }
}